import {Button, Form, Input, Typography} from 'components'
import {formatQueryString, getQueryParams} from 'util/url'
import {useHistory, useLocation} from 'react-router'

import DefaultThemeProvider from 'app/DefaultThemeProvider'
import {getLoginPathname} from 'util/routing'
import styled from '@emotion/styled/macro'
import {useState} from 'react'

const CenteredDiv = styled.div`
  text-align: center;
`

function PasteTokenLoginComponent() {
  const {search} = useLocation()
  const history = useHistory()
  const [token, setToken] = useState('')
  const queryParams = getQueryParams(search)

  const onSubmit = () => {
    // redirect to login link again with `ott` replaced
    // should be identical to url created by OneTimeToken.get_link_with_auth
    const newQueryParams = {
      ...queryParams,
      ott: token,
      pasted: '1',
    }

    history.push({
      pathname: getLoginPathname(),
      search: formatQueryString(newQueryParams),
    })
  }

  return (
    <DefaultThemeProvider>
      <Form onSubmit={onSubmit}>
        <CenteredDiv>
          <Typography variant="body1">
            Provide the login code from your email to continue
          </Typography>
          <Input
            label="Login code"
            name="token"
            value={token}
            onChange={({currentTarget: {value}}) => setToken(value.trim())}
            required
          />
          <Button>Log in</Button>
        </CenteredDiv>
      </Form>
    </DefaultThemeProvider>
  )
}

export default PasteTokenLoginComponent
