import {useCallback, useMemo} from 'react'

import LoginComponent from './LoginComponent'
import {LoginMethod} from 'app/enums'
import PasteTokenLoginComponent from './PasteTokenLoginComponent'
import {Typography} from 'components'
import analytics from 'analytics'
import cv from 'util/clientVars'
import {getAboutPasswordlessPathname} from 'util/routing'
import {getQueryParams} from 'util/url'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useLocation} from 'react-router'
import withScreenData from 'app/withScreenData'

const BorderedDiv = styled.div`
  border: 1px solid ${styles.colors.neutral300};
  padding: ${styles.space.m};
  width: 400px;
`

const Centered = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

function LoginScreen({data}) {
  const getOnConnect = useCallback(
    (loginMethod) => (accountInfo) => {
      const {user, organizer_logged_in_until_ts} = accountInfo
      const {id, has_logged_in} = user
      analytics.trackLoginSucceeded({
        user_id: id,
        has_previously_logged_in: has_logged_in,
        login_method: loginMethod,
        organizer_logged_in_until_ts,
        page: 'login',
      })
      window.location.reload()
    },
    []
  )

  const search = useLocation().search
  const queryParams = useMemo(() => getQueryParams(search), [search])
  const forOttInput = queryParams.ott && queryParams.user_id

  const needs_reauth = queryParams.needs_reauth || ''
  const explanationMessage = needs_reauth
    ? 'Please re-authenticate to see this page.'
    : 'Please log in to see this page'

  return (
    <Centered>
      <Typography variant="h2">Log in to {cv.product_name}</Typography>
      <BorderedDiv>
        {forOttInput && !queryParams.pasted ? (
          <PasteTokenLoginComponent />
        ) : (
          <LoginComponent
            onFacebookConnected={getOnConnect(LoginMethod.FACEBOOK)}
            onGoogleConnected={getOnConnect(LoginMethod.GOOGLE)}
            headerText={explanationMessage}
            email={data.current_user_email}
          />
        )}
      </BorderedDiv>

      {!forOttInput && (
        <Typography variant="h4">
          <a href={getAboutPasswordlessPathname()}>
            Why don't I need a password?
          </a>
        </Typography>
      )}
    </Centered>
  )
}

export default withScreenData(LoginScreen)
