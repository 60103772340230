/**
 * Component to replace the SignupForm or DonationForm when the event is no
 * longer taking signups.
 */

import {Button, Icon, InputGroup} from 'components'
import {centsForDisplayInWholeDollars, centsToDollars} from 'util/currency'
import styles, {getBorderRadiusFromProps} from 'components/styles'

import {F} from 'util/i18n'
import IntlMessageContext from 'app/IntlMessageContext'
import {createMailUrl} from 'util/url'
import {formatSelfCheckInTimeStart} from 'util/timeslot'
import {getEventTypeAffinityChecks} from 'util/event'
import {getOrganizationEventUrl} from 'util/routing'
import styled from '@emotion/styled/macro'
import {useContext} from 'react'

export default function EventClosedBox({
  event,
  checkInStartTime,
  displayEventAsPassed,
  displayEventAsFull,
}) {
  const contactEmail = event.organization.reply_to_email || ''
  const mailtoLink =
    contactEmail &&
    createMailUrl(
      contactEmail,
      `Question about “${event.name}”`,
      `Hello ${event.organization.name},\n` +
        `I have a question about…\n\n\n` +
        `📅 ${event.name}\n` +
        getOrganizationEventUrl(event.organization, event).toString()
    )

  function handleContactClick() {
    window.open(mailtoLink, '_blank', 'noopener noreferrer')
  }

  const message = (
    <PrimaryMessage
      event={event}
      checkInStartTime={checkInStartTime}
      displayEventAsPassed={displayEventAsPassed}
      displayEventAsFull={displayEventAsFull}
    />
  )
  const secondaryMessage = <SecondaryMessage event={event} />

  return (
    <ErrorMessageWrapper>
      <div>{message}</div>
      {secondaryMessage && (
        <SecondaryMessageDiv>{secondaryMessage}</SecondaryMessageDiv>
      )}
      <InputGroup center stackOnMobile stackAlways>
        {contactEmail && (
          <ErrorMessageButtons>
            <Button secondary fluid onClick={handleContactClick}>
              <F defaultMessage="Contact organization" />
            </Button>
          </ErrorMessageButtons>
        )}{' '}
      </InputGroup>{' '}
    </ErrorMessageWrapper>
  )
}

function PrimaryMessage({
  event,
  checkInStartTime,
  displayEventAsPassed,
  displayEventAsFull,
}) {
  const {
    intlMessageConfig: {locale},
  } = useContext(IntlMessageContext)
  const {hasFundraiserForm, isDonationCampaign} = getEventTypeAffinityChecks(
    event
  )

  if (hasFundraiserForm) {
    if (isDonationCampaign) {
      return <F defaultMessage="This fundraising campaign has ended." />
    }

    return <F defaultMessage="This fundraising event has ended." />
  }

  if (displayEventAsPassed) {
    return <F defaultMessage="This event has ended." />
  }

  if (checkInStartTime && new Date(checkInStartTime) > new Date()) {
    const startTimeFormatted = formatSelfCheckInTimeStart(
      event,
      checkInStartTime,
      locale
    )
    return (
      <F
        defaultMessage="Self check-in is not available until: {startTimeFormatted}"
        values={{startTimeFormatted}}
      />
    )
  }

  if (displayEventAsFull) {
    return <F defaultMessage="This event is at max capacity." />
  }
  return null
}

function SecondaryMessage({event}) {
  const {hasFundraiserForm} = getEventTypeAffinityChecks(event)
  if (!hasFundraiserForm) {
    return null
  }

  const totalDonations = event.fundraiser_total_raised_in_cents || 0
  const totalDonationsInDollars = centsToDollars(totalDonations)
  const totalDonationsString = centsForDisplayInWholeDollars(totalDonations)

  const goalInCents = event.fundraising_goal_amount_in_cents || 0
  const goalString = goalInCents && centsForDisplayInWholeDollars(goalInCents)

  const showAmountRaised =
    totalDonationsInDollars > 100 &&
    (!goalInCents || totalDonations > goalInCents)

  if (!showAmountRaised) {
    return null
  }

  return (
    <>
      <Icon name="hand-holding-heart" aria-label="hand holding heart" />
      {goalInCents ? (
        <F
          defaultMessage="{total} raised of {goal} goal"
          values={{total: totalDonationsString, goal: goalString}}
        />
      ) : (
        <F
          defaultMessage="{total} raised"
          values={{total: totalDonationsString}}
        />
      )}
    </>
  )
}

const SecondaryMessageDiv = styled.div`
  font-weight: ${styles.typography.fontWeightNormal};
`

const ErrorMessageButtons = styled.div`
  margin-top: ${styles.space.m};
`

const ErrorMessageWrapper = styled.div`
  ${(props) => getBorderRadiusFromProps(props)};

  background-color: ${styles.colors.neutral100};
  border: 1px solid ${styles.colors.neutral300};
  padding: ${styles.space.m};
  text-align: center;
  font-weight: ${styles.typography.fontWeightBold};
`
