import {
  Autocomplete,
  Button,
  CardModal,
  Form,
  Input,
  InputGroup,
  Link,
  Message,
  MessageType,
  Select,
  Typography,
} from 'components'
import {addOrgFeedMembershipAffirmation, setFeedUserData} from 'redux/actions'

import {F} from 'util/i18n'
import TurnstileWidget from 'components/TurnstileWidget'
import {UsState} from 'app/enums'
import api from 'data/api'
import clientVars from 'util/clientVars'
import {compose} from 'util/common'
import {connect} from 'react-redux'
import moment from 'vendor/moment'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useAsync} from 'hooks/async'
import useIdentityFields from 'hooks/identity-fields'
import {useState} from 'react'

const MainContentContainer = styled.div`
  margin: ${styles.space.m};
`

const HeaderWrapper = styled.div`
  margin-bottom: ${styles.space.m};
`

const ErrorContent = styled.div`
  text-align: center;
`

const ButtonsWrapper = styled.div`
  margin-top: ${styles.space.s};
  margin-bottom: ${styles.space.s};
  text-align: center;
`

const DisclaimerWrapper = styled.div`
  margin-top: ${styles.space.l};
  text-align: center;
`

const DisclaimerText = styled.div`
  font-size: ${styles.typography.fontSizeXS};
  color: ${styles.colors.neutral400};
  padding-bottom: ${styles.space.s};
`

// all membership modal confirmations should expire after 1 year
const NUM_YEARS_MEMBERSHIP_IS_VALID = 1

function MembershipConfirmationModal({
  organization,
  trackingParams,
  lastAffirmedFeedMembershipsAt,
  onMembershipSet,
}) {
  const [membershipError, setMembershipError] = useState(null)
  const [showTurnstile, setShowTurnstile] = useState(false)

  const {
    email,
    zip,
    firstName,
    lastName,
    phone,
    addressLine1,
    addressLine2,
    city,
    state,
  } = useIdentityFields()

  const [person, setPerson] = useState({
    email,
    zip,
    firstName,
    lastName,
    phone,
    addressLine1,
    addressLine2,
    city,
    state,
  })

  const [selectedChildOrgId, setSelectedChildOrgId] = useState(null)
  const childOrgsState = useAsync(async () => {
    if (!organization.include_child_org_affiliation_field) return []
    return (
      await api.fetchChildOrganizations(organization.slug, {order_by: 'name'})
    ).organizations
  }, [organization])
  const childOrgOptions = (childOrgsState.result || []).map((o) => ({
    text: o.name,
    value: o.id,
  }))

  const handleSubmit = async (turnstileToken = undefined) => {
    if (clientVars.turnstile_enabled) {
      if (turnstileToken === undefined) {
        setShowTurnstile(true)
        return
      } else {
        setShowTurnstile(false)
      }
    }

    setMembershipError(null)
    try {
      const response = await api.affiliateWithOrg({
        orgSlug: organization.slug,
        person,
        trackingParams,
        promotionalSmsOptIn: true,
        childOrgAffiliationId: selectedChildOrgId,
        affirmMembership: true,
        turnstileToken,
      })
      onMembershipSet(
        organization,
        response.user_last_affirmed_membership_at,
        person
      )
    } catch (error) {
      setMembershipError(error)
    }
  }

  const onChange = (evt) => {
    const {name, value} = evt.target
    setPerson({...person, [name]: value})
  }

  const maybeLastAffirmedMembershipAt =
    lastAffirmedFeedMembershipsAt[organization?.id]
  const withinOneYear =
    maybeLastAffirmedMembershipAt &&
    moment().diff(moment(maybeLastAffirmedMembershipAt), 'years') <
      NUM_YEARS_MEMBERSHIP_IS_VALID
  const maybeInvalidationDate =
    organization.feed_membership_must_be_affirmed_after
  const afterInvalidationDateIfApplicable =
    maybeLastAffirmedMembershipAt &&
    (maybeInvalidationDate
      ? moment(maybeLastAffirmedMembershipAt).isAfter(
          moment(maybeInvalidationDate)
        )
      : true)
  const membershipConfirmed =
    organization && maybeLastAffirmedMembershipAt
      ? withinOneYear && afterInvalidationDateIfApplicable
      : null

  if (
    !organization ||
    !organization.require_membership_for_feed ||
    membershipConfirmed
  ) {
    return null
  }

  const US_REGION_OPTIONS = Object.entries(UsState).map(
    ([abbreviation, name]) => ({
      key: abbreviation,
      // $FlowFixMe - Flow thinks name is mixed even though we know it is a string
      text: name,
      value: abbreviation,
    })
  )

  const {
    override_membership_button_text,
    override_membership_decline_button_text,
    override_membership_org_name,
    require_membership_decline_link,
    require_membership_sms_disclaimer,
    require_membership_membership_disclaimer,
  } = organization
  const orgDisplayName = override_membership_org_name
    ? override_membership_org_name
    : organization.name

  return (
    <CardModal
      isOpen
      onRequestClose={() => {}}
      shouldCloseOnOverlayClick={false}
      hideCloseIcon
    >
      <MainContentContainer>
        <HeaderWrapper>
          <Typography variant="h2" center>
            Join {orgDisplayName} to take action
          </Typography>
        </HeaderWrapper>
        <Form
          onSubmit={async (evt) => {
            evt.preventDefault()
            await handleSubmit()
          }}
        >
          <InputGroup>
            <Input
              name="firstName"
              type="text"
              label="First Name"
              required
              value={person.firstName}
              onChange={onChange}
            />
            <Input
              name="lastName"
              type="text"
              label="Last Name"
              required
              value={person.lastName}
              onChange={onChange}
            />
          </InputGroup>
          <Input
            name="email"
            type="text"
            label="Email"
            required
            value={person.email}
            onChange={onChange}
          />
          <InputGroup>
            <Input
              isZIPCode
              name="zip"
              label="ZIP Code"
              value={person.zip}
              onChange={onChange}
            />
            <Input
              name="phone"
              type="tel"
              label="Mobile number"
              value={person.phone}
              onChange={onChange}
            />
          </InputGroup>
          {organization.include_child_org_affiliation_field && (
            <>
              <InputGroup>
                <Autocomplete
                  label={
                    organization.override_child_org_affiliation_field_label || (
                      <F defaultMessage="Local chapter" />
                    )
                  }
                  required={
                    organization.require_child_org_affiliation_field ?? true
                  }
                  disabled={childOrgsState.loading}
                  value={selectedChildOrgId}
                  onChange={(e, {value}) => setSelectedChildOrgId(value)}
                  options={childOrgOptions}
                />
              </InputGroup>
            </>
          )}
          {organization.include_address_fields_in_membership_modal && (
            <>
              <InputGroup>
                <Input
                  name="addressLine1"
                  type="text"
                  label="Street address"
                  value={person.addressLine1}
                  onChange={onChange}
                />
                <Input
                  name="addressLine2"
                  type="text"
                  label="Apt., suite, etc."
                  value={person.addressLine2}
                  onChange={onChange}
                />
              </InputGroup>
              <InputGroup>
                <Input
                  name="city"
                  type="text"
                  label="City"
                  value={person.city}
                  onChange={onChange}
                />
                <Select
                  name="state"
                  label="State"
                  value={person.state}
                  options={US_REGION_OPTIONS}
                  onChange={(e, data) =>
                    setPerson({...person, state: data.value})
                  }
                />
              </InputGroup>
            </>
          )}
          {membershipError && (
            <Message type={MessageType.ERROR}>
              <ErrorContent>
                {membershipError.message || 'Error submitting membership form.'}
              </ErrorContent>
            </Message>
          )}
          <ButtonsWrapper>
            {showTurnstile ? (
              <TurnstileWidget
                action="membership-confirmation-modall"
                onVerify={handleSubmit}
              />
            ) : (
              <Button
                fluid
                type="submit"
                style={{marginBottom: styles.space.s}}
              >
                {override_membership_button_text ? (
                  override_membership_button_text
                ) : (
                  <>
                    Count me in as a member of
                    <br />
                    {orgDisplayName}
                  </>
                )}
              </Button>
            )}
            <Link
              data-testid="decline-link"
              to={require_membership_decline_link || '/'}
            >
              <Button fluid secondary type="button">
                {override_membership_decline_button_text
                  ? override_membership_decline_button_text
                  : "No, I don't want to be a member"}
              </Button>
            </Link>
          </ButtonsWrapper>
          <DisclaimerWrapper>
            <DisclaimerText>
              {require_membership_membership_disclaimer || (
                <F
                  defaultMessage="By clicking “Count me in as a member of {orgDisplayName}” you
                  are confirming you want to be a member of {orgDisplayName}. If
                  you are already a member, clicking the button will reaffirm
                  your membership. Additionally, you agree to the
                  <TosLink>
                    Terms of Service
                  </TosLink>
                  and
                  <PrivacyPolicyLink>Privacy Policy</PrivacyPolicyLink>."
                  values={{
                    TosLink: (msg) => (
                      <Link to={clientVars.tos_url} target="_blank">
                        {msg}
                      </Link>
                    ),
                    PrivacyPolicyLink: (msg) => (
                      <Link to={clientVars.privacy_policy_url} target="_blank">
                        {msg}
                      </Link>
                    ),
                    orgDisplayName,
                  }}
                />
              )}
            </DisclaimerText>
            <DisclaimerText>
              {require_membership_sms_disclaimer || (
                <F
                  defaultMessage="By providing your mobile number, you agree to receive
                  occasional automated text messages from {orgDisplayName} and
                  {productName} with updates about how to stay
                  involved, such as recommended events and other actions.
                  Message and data rates may apply. Message frequency varies.
                  Text STOP to cancel or HELP for help.
                  <Link>SMS Shortcode Terms of Service</Link>."
                  values={{
                    Link: (msg) => (
                      <Link target="_blank" to={clientVars.tos_url}>
                        {msg}
                      </Link>
                    ),
                    orgDisplayName,
                    productName: clientVars.product_name,
                  }}
                />
              )}
            </DisclaimerText>
          </DisclaimerWrapper>
        </Form>
      </MainContentContainer>
    </CardModal>
  )
}

const mapStateToProps = (state) => {
  return {
    lastAffirmedFeedMembershipsAt: state.lastAffirmedFeedMembershipsAt,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMembershipSet: (org, membershipAffirmedAt, person) => {
      dispatch(addOrgFeedMembershipAffirmation(org, membershipAffirmedAt))
      dispatch(setFeedUserData(person))
    },
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MembershipConfirmationModal
)
