import {Input, InputGroup} from 'components'
import {defineMessages, useIntl} from 'util/i18n'

const signupFormLabels = defineMessages({
  firstName: {defaultMessage: 'First name'},
  lastName: {defaultMessage: 'Last name'},
  email: {defaultMessage: 'Email'},
  emailOptional: {defaultMessage: 'Email (optional)'},
  phone: {defaultMessage: 'Mobile number'},
  phoneOptional: {defaultMessage: 'Phone (optional)'},
  mobilePhoneOptional: {defaultMessage: 'Mobile number (optional)'},
  zipcode: {defaultMessage: 'ZIP code'},
  zipcodeOptional: {defaultMessage: 'ZIP (optional)'},
})

export default function UserSignupFormFields({
  errorFields,
  identityFields,
  onChange,
  isGroupMemberSignup,
  phoneIsOptional,
}) {
  const intl = useIntl()
  const handleChange = (evt) => {
    const {name, value} = evt.target
    onChange({name, value})
  }

  const shouldRequirePhoneNumber = !phoneIsOptional && !isGroupMemberSignup

  return (
    <>
      <InputGroup>
        <Input
          name="firstName"
          type="text"
          label={intl.formatMessage(signupFormLabels.firstName)}
          required
          value={identityFields.firstName}
          onChange={handleChange}
          error={errorFields.first_name}
        />
        <Input
          name="lastName"
          type="text"
          label={intl.formatMessage(signupFormLabels.lastName)}
          required
          value={identityFields.lastName}
          onChange={handleChange}
          error={errorFields.last_name}
        />
      </InputGroup>
      <Input
        name="email"
        type="email"
        label={intl.formatMessage(
          isGroupMemberSignup
            ? signupFormLabels.emailOptional
            : signupFormLabels.email
        )}
        fluid
        required={!isGroupMemberSignup}
        value={identityFields.email}
        onChange={handleChange}
        error={!!errorFields.email}
      />
      <InputGroup stackAlwaysNoRightMargin>
        <Input
          name="phone"
          type="tel"
          label={intl.formatMessage(
            shouldRequirePhoneNumber
              ? signupFormLabels.phone
              : isGroupMemberSignup
              ? signupFormLabels.phoneOptional
              : signupFormLabels.mobilePhoneOptional
          )}
          required={shouldRequirePhoneNumber}
          value={identityFields.phone}
          onChange={handleChange}
          error={!!errorFields.phone}
        />
        <Input
          isZIPCode
          name="zip"
          label={intl.formatMessage(
            isGroupMemberSignup
              ? signupFormLabels.zipcodeOptional
              : signupFormLabels.zipcode
          )}
          required={!isGroupMemberSignup}
          value={identityFields.zip}
          onChange={handleChange}
          error={!!errorFields.zipcode}
        />
      </InputGroup>
    </>
  )
}
