import {Button, Typography} from 'components'
import {
  formatIdentityFieldsForSignupRequest,
  getIdentityFieldsFromFormValues,
} from 'util/user'
import {useContext, useEffect, useRef, useState} from 'react'

import {EMPTY_STREET_ADDRESS_FIELDS_FOR_SIGNUP_REQUEST} from 'app/constants'
import Form from 'components/Form'
import TrackingParamsContext from 'app/TrackingParamsContext'
import UserSignupFormFields from 'events/components/UserSignupFormFields'
import {customFieldsValueMapper} from 'events/details/SignupForm/CustomFields'
import {getGroupSignupCounts} from '../../util/groupSignup'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const ContentWrapper = styled.div`
  padding-top: 3rem;
  text-align: center;
  margin-bottom: ${styles.space.xl};
`
const CancelButtonWrapper = styled.div`
  padding-top: 1rem;
`

const SignupFieldWrapper = styled.div`
  padding-top: ${styles.space.s};
`
const NumberWrapper = styled.div`
  display: flex;
  float: left;
`
const FieldsWrapper = styled.div`
  padding-left: 1.5rem;
`

const FormButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
`
const MoreFieldsButtonWrapper = styled.div`
  display: inline;
  float: right;
`

const RemoveContactButtonWrapper = styled.div`
  float: left;
  display: flex;
  width: 71%;
`

export default function AddGroupContactsForm({
  event,
  participation,
  groupLeaderUserId,
  onAddGroupMembers,
}) {
  const ref = useRef(null)

  useEffect(() => {
    const timeOutId = setTimeout(
      () => ref.current?.scrollIntoView({behavior: 'smooth'}),
      100
    )
    return () => clearTimeout(timeOutId)
  })
  const {trackingParams} = useContext(TrackingParamsContext)

  const [displayAddContactForm, setDisplayAddContactForm] = useState(false)
  const [fieldValues, setFieldValues] = useState([
    {userFields: {}, customFields: []},
  ])

  const {
    numUnclaimedGroupSpots,
    numClaimedGroupSpotsPlusLeaderIfNotCancelled,
  } = getGroupSignupCounts(participation)

  function cancel() {
    setFieldValues([{userFields: {}, customFields: []}])
    setDisplayAddContactForm(false)
  }

  function calculateNextIndices(i) {
    return numClaimedGroupSpotsPlusLeaderIfNotCancelled + i + 1
  }

  return (
    <ContentWrapper>
      <Button
        onClick={() => {
          displayAddContactForm ? cancel() : setDisplayAddContactForm(true)
        }}
        ref={ref}
        icon={displayAddContactForm ? 'angle-up' : 'angle-down'}
        iconPosition={'right-inline'}
        disabled={numUnclaimedGroupSpots === 0}
        secondary
        fluid
        data-testid={'display-contact-form'}
      >
        Add contacts to your reservation
      </Button>
      {displayAddContactForm && (
        <Form
          onSubmit={() => {
            const signupRequest = {
              signups: fieldValues.map((values) => ({
                affiliation_id: participation.affiliation.id,
                shifts: [{timeslot_id: participation.timeslot_id}],
                referring_user_id: groupLeaderUserId,
                referring_participation_id: participation.id,
                referring_data_signature:
                  participation.group_invite_signature || '',
                sharer_role: null,
                smsOptIn: false,
                createdFromDashboard: false,
                is_promoted: !event.current_org_is_owner_or_co_owner,
                event_suggestion_context: null,
                ...formatIdentityFieldsForSignupRequest(
                  getIdentityFieldsFromFormValues(values.userFields)
                ),
                custom_signup_fields: values.customFields,
                ...EMPTY_STREET_ADDRESS_FIELDS_FOR_SIGNUP_REQUEST,
                ...trackingParams,
              })),
            }
            onAddGroupMembers(signupRequest)
            setFieldValues([{userFields: {}, customFields: []}])
            setDisplayAddContactForm(false)
          }}
        >
          {fieldValues.map((form, i) => (
            <div key={i}>
              <SignupFieldWrapper>
                <NumberWrapper>
                  <Typography variant={'body1'}>
                    {calculateNextIndices(i) + '.'}
                  </Typography>
                </NumberWrapper>
                <FieldsWrapper>
                  <UserSignupFormFields
                    errorFields={{}}
                    identityFields={getIdentityFieldsFromFormValues(
                      form.userFields
                    )}
                    onChange={({name, value}) => {
                      const updatedValues = [...fieldValues]
                      updatedValues[i] = {
                        customFields: updatedValues[i].customFields,
                        userFields: {
                          ...updatedValues[i].userFields,
                          [name]: value,
                        },
                      }
                      setFieldValues(updatedValues)
                    }}
                    isGroupMemberSignup
                  />
                </FieldsWrapper>
              </SignupFieldWrapper>
              <FormButtonsWrapper>
                <RemoveContactButtonWrapper>
                  <Button
                    link
                    onClick={() => {
                      i === 0
                        ? setDisplayAddContactForm(false)
                        : setFieldValues(
                            fieldValues.filter((_, num) => i !== num)
                          )
                    }}
                    padding="none"
                    type="button"
                    icon="angle-up"
                    linkTextColor={styles.colors.error200}
                  >
                    Remove
                  </Button>
                </RemoveContactButtonWrapper>

                {i === fieldValues.length - 1 && (
                  <MoreFieldsButtonWrapper>
                    <Button
                      onClick={() => {
                        setFieldValues([
                          ...fieldValues,
                          {
                            userFields: {},
                            customFields: customFieldsValueMapper(event),
                          },
                        ])
                      }}
                      icon="plus"
                      link
                      iconPosition="left-inline"
                      type="button"
                      disabled={fieldValues.length >= numUnclaimedGroupSpots}
                    >
                      Add more
                    </Button>
                  </MoreFieldsButtonWrapper>
                )}
              </FormButtonsWrapper>
            </div>
          ))}

          <Button fluid type="submit">
            Add contacts
          </Button>
          <CancelButtonWrapper>
            <Button
              secondary
              fluid
              type="cancel"
              onClick={cancel}
              data-testid={'cancel-button'}
            >
              Cancel
            </Button>
          </CancelButtonWrapper>
        </Form>
      )}
    </ContentWrapper>
  )
}
